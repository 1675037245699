import { APIFilter } from '@/utils/api'

export default {
  async selectLoperacioncrm (Vue, filter, search, sorter, page, idoperacioncrm) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idoperacioncrm', idoperacioncrm)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.loperacioncrm.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'loperacioncrm.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLoperacioncrmRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idloperacioncrm', pks)
    const resp = await Vue.$api.call('loperacioncrm.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
